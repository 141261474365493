window.addEventListener('load', () => {

    const faqItems            = document.getElementsByClassName('faq__item');
    const accountType         = document.getElementsByClassName('step__first_button');
    const stepSecondIndicator = document.getElementsByClassName('step__second');
    const formContent         = document.getElementById('form-content');
    const stepFirstBlock      = document.getElementById('step-first-block');
    const stepFirstButton     = document.getElementById('step-first');
    const stepSecond          = document.getElementById('step-second-block');
    const stepThird           = document.getElementById('step-third-block');
    const stepThirdButton     = document.getElementById('step-third');
    const messageButton       = document.getElementById('message-button');
    const messageWrapper      = document.getElementById('message-wrapper');

    const turnstileEnabled        = window.cloudflareTurnstile !== undefined,
          getTurnstileContainerId = (formElement) => $(formElement).find('[data-type=cf-turnstile]').attr('id');

    const emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    $.validator.methods.email = function (value, element) {
        return this.optional(element) || emailRegExp.test(value);
    };

    new Swiper('.testimonials__swiper', {
        loop: true,
        centeredSlides: true,
        slidesPerView: 2.85,
        spaceBetween: -65,
        allowTouchMove: true,
        pagination: {
            el: '.swiper-pagination', clickable: true,
        }, navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        }, breakpoints: {
            // when window width is >= 320px
            200: {
                spaceBetween: 10,
                slidesPerView: 1.15,
            },
            420: {
                spaceBetween: 10,
                slidesPerView: 1.3,
            },
            600: {
                spaceBetween: 10,
                slidesPerView: 1.5,
            },
            826: {
                spaceBetween: -35,
            },
            1000: {
                spaceBetween: -45,
            },
            1280: {
                spaceBetween: -55,
            },
            1400: {
                slidesPerView: 2.85,
                spaceBetween: -65,
            },
        },
    });

    for (let i = 0; i < faqItems.length; i++) {
        faqItems[i].addEventListener('click', () => {
            for (let j = 0; j < faqItems.length; j++) {
                if (j != i) {
                    faqItems[j].classList.remove('faq__item_active');
                }
            }
            if (faqItems[i].classList.contains('faq__item_active')) {
                faqItems[i].classList.remove('faq__item_active');
            } else {
                faqItems[i].classList.add('faq__item_active');
            }
        });
    }

    for (let i = 0; i < accountType.length; i++) {
        accountType[i].addEventListener('click', () => {
            const formType = accountType[i].getAttribute('data-type');

            formContent.classList.add('form__content_' + formType);
            for (let j = 0; j < stepSecondIndicator.length; j++) {
                stepSecondIndicator[j].classList.add('contact-us__step_active');
            }
            stepFirstBlock.classList.toggle('step__hidden');
            stepFirstButton.classList.add('contact-us__step_click');
            stepSecond.classList.toggle('step__hidden');

            if (turnstileEnabled) {
                const turnstileContainerId = getTurnstileContainerId($('form[data-type=' + formType + ']'));

                if (turnstileContainerId && !cloudflareTurnstile.isRendered(turnstileContainerId)) {
                    cloudflareTurnstile.onload(function () {
                        cloudflareTurnstile.render(turnstileContainerId);
                    });
                }
            }
        });
    }

    stepFirstButton.addEventListener('click', () => {
        for (let j = 0; j < stepSecondIndicator.length; j++) {
            stepSecondIndicator[j].classList.remove('contact-us__step_active');
        }
        stepFirstBlock.classList.toggle('step__hidden');
        stepFirstButton.classList.remove('contact-us__step_click');
        stepSecond.classList.toggle('step__hidden');
        formContent.classList = [];
    });

    $.fn.isInViewport = function () {
        let elementTop    = $(this).offset().top;
        let elementBottom = elementTop + $(this).outerHeight();

        let viewportTop    = $(window).scrollTop();
        let viewportBottom = viewportTop + $(window).height();

        return elementBottom > viewportTop && elementTop < viewportBottom;
    };

    $('.main__title').each(function () {
        if ($(this).isInViewport()) {
            messageButton.classList.add('message__button_hidden');
            messageButton.classList.remove('message__button_active');
            messageWrapper.classList.remove('message__wrapper_socials');
        } else {
            messageButton.classList.remove('message__button_hidden');
        }
    });


    $(window).on('resize scroll', function () {
        $('.main__title').each(function () {
            if ($(this).isInViewport()) {
                messageButton.classList.add('message__button_hidden');
                messageButton.classList.remove('message__button_active');
                messageWrapper.classList.remove('message__wrapper_socials');
            } else {
                messageButton.classList.remove('message__button_hidden');
            }
        });
    });

    const initFormEvents = (form) => {
        const radioMessengerName = form.getElementsByClassName('radio-messenger-name');
        const messengerInput     = form.getElementsByClassName('messenger__name');

        for (let i = 0; i < radioMessengerName.length; i++) {
            radioMessengerName[i].addEventListener('change', () => {
                if (radioMessengerName[i].value == 'telegram') {
                    for (let j = 0; j < messengerInput.length; j++) {
                        messengerInput[j].placeholder = '@';
                    }
                } else if (radioMessengerName[i].value == 'skype') {
                    for (let j = 0; j < messengerInput.length; j++) {
                        messengerInput[j].placeholder = 'live:';
                    }
                }
            });

            if (radioMessengerName[i].checked) {
                radioMessengerName[i].dispatchEvent(new Event('change'));
            }
        }

        const validator = $(form).validate({
            highlight: function(element, errorClass, validClass) {
                $(element).closest('.form__field').addClass(errorClass);
            },
            unhighlight: function(element, errorClass, validClass) {
                $(element).closest('.form__field').removeClass(errorClass);
            },
            errorPlacement: function(error, element) {
                error.addClass('form__error').insertAfter(element);
            },
            submitHandler: function(form) {
                const url = $(form).attr('action');

                const sendSubmitRequest = () => {
                    const $submitButton = $(form).find('button[type=submit]');

                    $submitButton.prepend($('<img class="loader">').attr({'src': $submitButton.data('loader-src')}));
                    $submitButton.prop('disabled', true);

                    $.ajax({
                        type: 'POST',
                        url,
                        data: $(form).serialize(),
                        success: function (data) {
                            validator.resetForm();

                            stepFirstButton.classList.remove('contact-us__step_click');
                            stepSecond.classList.add('step__hidden');
                            stepThirdButton.classList.add('contact-us__step_active');
                            stepThird.classList.remove('step__hidden');
                        },
                        error: function ({responseJSON: data}) {
                            const newForm = $(data.form);

                            $(form).replaceWith(newForm);

                            initFormEvents(newForm[0]);

                            if (turnstileEnabled) {
                                cloudflareTurnstile.render(getTurnstileContainerId(form))
                            }
                        },
                    });
                };

                sendSubmitRequest();
            }
        });
    };

    for (const form of document.getElementsByTagName('form')) {
        initFormEvents(form);
    }

    const horizontalSections = gsap.utils.toArray('.horizontal-sections');

    horizontalSections.forEach((section, i) => {
        const thisPinWrap  = section.querySelector('.pin-wrap');
        const thisAnimWrap = thisPinWrap.querySelector('.animation-wrap');

        const getToValue = () => -(thisAnimWrap.scrollWidth - window.innerWidth);

        gsap.fromTo(thisAnimWrap, {
            x: () => thisAnimWrap.classList.contains('to-right') ? 0 : getToValue(),
        }, {
            x: () => thisAnimWrap.classList.contains('to-right') ? getToValue() : 0, ease: 'none', scrollTrigger: {
                trigger: section,
                start: 'top top',
                end: () => '+=' + (thisAnimWrap.scrollWidth - window.innerWidth),
                pin: thisPinWrap,
                invalidateOnRefresh: true,
                scrub: true,
            }, media: '(min-width: 1024px)',
        });
    });

    let mySwiper = undefined;

    function initSwiper() {
        if (window.innerWidth <= 1025 && mySwiper == undefined) {
            mySwiper = new Swiper('.about-us__swiper', {
                slidesPerView: 1.5, spaceBetween: 0, allowTouchMove: true, slidesPerGroup: 1, pagination: {
                    el: '.swiper-pagination1', clickable: true,
                }, breakpoints: {
                    320: {
                        slidesPerView: 1.5,
                    }, 480: {
                        slidesPerView: 1.8,
                    }, 750: {
                        slidesPerView: 3,
                    },
                },
            });
        } else if (window.innerWidth > 1025 && mySwiper != undefined) {
            if (mySwiper) {
                mySwiper.destroy();
                mySwiper = undefined;
            }
        }
    }

    initSwiper();

    window.addEventListener('resize', () => {
        initSwiper();
    });

    gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

    gsap.from('.cooperation__arrows_right', {
        x: '90%', ease: 'linear', scrollTrigger: {
            trigger: '.cooperation__section', start: 'top 50%',
        },
    });

    gsap.from('.cooperation__arrows_left', {
        x: '-90%', ease: 'linear', scrollTrigger: {
            trigger: '.cooperation__section', start: 'top 50%',
        },
    });

    gsap.from('.contact-us__arrows', {
        x: '90%', ease: 'linear', scrollTrigger: {
            trigger: '.contact-us__section', start: 'top 10%',
        },
    });

    gsap.from('.about-us__container', {
        x: '80%', ease: 'linear', scrollTrigger: {
            trigger: '.about-us__container', start: 'top 90%', end: 'top 20%', // markers: true,
            // scrub:true,
        },
    });

    gsap.from('.for-advertisers__arrows', {
        x: '-90%', ease: 'linear', scrollTrigger: {
            trigger: '.for-advertisers__section', start: 'top 50%',
        },
    });


});

